body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Domine";
  src: url('fonts/domine-latin-600-normal.woff2') format('woff2');
  font-weight: 600;
}

@font-face {
  font-family: "Montserrat";
  src: url('fonts/montserrat-latin-700-normal.woff2') format('woff2');
  font-weight: 700;
}

@font-face {
  font-family: "Montserrat";
  src: url('fonts/montserrat-latin-800-normal.woff2') format('woff2');
  font-weight: 800;
}

@font-face {
  font-family: "Open Sans";
  src: url('fonts/open-sans-latin-400-normal.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url('fonts/open-sans-latin-400-italic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Roboto Mono";
  src: url('fonts/roboto-mono-latin-400-normal.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Roboto Mono";
  src: url('fonts/roboto-mono-latin-400-italic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Roboto Mono";
  src: url('fonts/roboto-mono-latin-700-normal.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Roboto Mono";
  src: url('fonts/roboto-mono-latin-700-italic.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
}

::-webkit-scrollbar {
  width: 8px;
  background: transparent;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: #00204020;
}